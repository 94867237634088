import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveTop from "../components/svg/curveTop"

const TermsPage = () => (

  	<Layout>

      <Seo title="Terms and Privacy Policy" />

      <section role="main" className="bg">
         <div className="container">
            <div className="row">
               <div className="sub-hero-content sub-hero">
                  <div className="sub-hero-text full">
                     <h1>Terms of Service</h1>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-btm">
         <CurveTop />
      </div>

      <section className="section-pad" id="anchor">
         <div className="container">
            <div className="row">
            <p>Last updated: 09/01/2021</p>
 
            <h5>1. Introduction</h5>
            <p>Welcome to Madclarity Pty Ltd (“Company”, “we”, “our”, “us”).</p>
  
            <p>These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at https://madclarity.com/ operated by Madclarity Pty Ltd.</p>
  
            <p>Your agreement with us includes these Terms (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>
  
            <h5>2. Content</h5>
            <p>Content found on or through this Service are the property of Madclarity Pty Ltd or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>
  
            <h5>3. Prohibited Uses</h5>
            <p>You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</p>
            <ol type="a">
               <li>In any way that violates any applicable national or international law or regulation.</li>
               <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</li>
               <li>To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</li>
               <li>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
               <li> To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</li>
            </ol>

            <p>Additionally, you agree not to:</p>

            <ol type="a">
               <li>Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service.</li>
               <li>Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</li>
               <li>Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</li>
               <li> Use any device, software, or routine that interferes with the proper working of Service.</li>
               <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</li>
               <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</li>
               <li>Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
               <li>Take any action that may damage or falsify Company rating.</li>
               <li>Otherwise attempt to interfere with the proper working of Service.</li>
            </ol>
  
            <h5>4. Analytics</h5>
  
            <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
  
            <p><strong>Google Analytics</strong></p>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
  
            <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en</p>
  
            <h5>5. Intellectual Property</h5>
            <p>Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Madclarity Pty Ltd.</p>
  
            <h5>6. Links To Other Web Sites</h5>
            <p>Our Service may contain links to third party web sites or services that are not owned or controlled by Madclarity Pty Ltd.</p>
  
            <p>Madclarity Pty Ltd has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
  
            <p>YOU ACKNOWLEDGE AND AGREE THAT Madclarity Pty Ltd SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>
  
            <p>WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>
  
            <h5>7. Governing Law</h5>     
            <p>These Terms shall be governed and construed in accordance with the laws of New South Wales, Australia without regard to its conflict of law provisions.</p>
  
            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>
  
            <h5>8. Amendments To Terms</h5>
            <p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
  
            <p>Your continued use of the Service following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
  
            <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</p>
  
            <h5>9. Waiver And Severability</h5>
            <p>No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
  
            <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>
  
            <h5>10. Acknowledgement</h5>
            <p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
  
            <h5>11. Contact Us</h5>
            <p>Please send comments or questions to us via this page on our website: http://madclarity.com/contact.</p>
            </div>
        </div>
     </section>
           

  </Layout>
)

export default TermsPage